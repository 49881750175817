@import "src/utilities/references/index";

.admin-login-form {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: $bg1;
    padding: 20px;

    p {
        font-size: 26px;
        font-weight: normal;
        color: $text4;
    }

    .context {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        min-width: 320px;
        max-width: 650px;
        //border: 0.5px solid $text4;
        width: 100%;
        padding: 20px;
        border-radius: 5px;
        row-gap: 24px;
        margin: 0 auto;

        .login {
            height: 46px;
            background-color: $accentLightBlue;
            color: #ffffff;
            font-weight: normal;
        }
    }
}