@import '../../utilities/references/index';

.vekalapp-admin-rule-page {
    header {
        background-color: transparent !important;
        box-shadow: none !important;
        width: unset !important;
        margin-top: -40px;
        max-width: 500px !important;
    }

    .header {
        display: flex;
        justify-content: end;
        align-items: center;
        flex-wrap: wrap;

        > span.spacer {
            flex: 1;
        }

        .title {
            display: flex;
            align-items: center;

            h3 {
                color: $text;
                font-size: 22px;
                margin-left: 10px;
            }

            .icons {
                display: flex;
                align-items: center;

                .divider {
                    width: 0.5px;
                    height: 20px;
                    background-color: $icon;
                    margin: 0 6px;
                }

                i {
                    color: $icon;
                    cursor: pointer;
                }
            }
        }

        .filter {
            display: flex;
            align-items: center;
            margin: 0 -8px;

            > div,
            button {
                margin: 0 8px;
            }

            #demo-simple-select {
                min-width: 100px;
            }
        }
    }

    .content {
        margin-top: 32px;

        .pagination {
            background-color: #fff;
            padding: 16px 0;
        }

        .categories {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: -16px;

            .grid {
                margin: 0 16px;
                min-width: 250px;
                flex: 1;
                padding: 0 16px;
            }
        }
    }
}

.upload_icons {
    background: #fff;
    color: rgba(0, 0, 0, 0.54);
    width: 100%;
    border: rgba(0, 0, 0, 0.54) solid 1px;
    border-radius: 5px;

    .upload_part {
        #update_icons {
            opacity: 0;
            position: absolute;
            z-index: -1;
        }
    }

    i {
        color: rgb(243, 46, 46);
    }

    span {
        color: rgba(0, 0, 0, 0.54);
    }

    label {
        height: 100%;
        text-align: center;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.enterDrag {
    span {
        border: none !important;
    }

    background: #fff;
    color: rgba(0, 0, 0, 0.54);
    width: 100%;
    animation: myfirst 6s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    background-image: linear-gradient(0deg, transparent, transparent 50%, rgb(82, 80, 80) 0, rgb(82, 80, 80)),
        linear-gradient(0deg, transparent, transparent, transparent 50%, rgb(82, 80, 80) 0, rgb(82, 80, 80)),
        linear-gradient(90deg, transparent, transparent 50%, rgb(82, 80, 80) 0, rgb(82, 80, 80)),
        linear-gradient(90deg, transparent, transparent 50%, rgb(82, 80, 80) 0, rgb(82, 80, 80));
    background-repeat: repeat-y, repeat-y, repeat-x, repeat-x;
    background-size: 2px 20px, 2px 20px, 20px 2px, 20px 2px;
    opacity: 0.7;
    border-radius: 8px;
}

@keyframes myfirst {
    0% {
        background-position: 0 0, 100% 100%, 100% 0, 0 100%;
    }
    100% {
        background-position: 0 +100px, 100% 0%, 0 0, 100% 100%;
    }
}
