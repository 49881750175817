@import '../../utilities/references/index';
@import '~leaflet/dist/leaflet.css';
.vekalapp-admin-locations-page {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .title {
            display: flex;
            align-items: center;
            h3 {
                color: $text;
                font-size: 22px;
                margin-left: 10px;
            }
            .icons {
                display: flex;
                align-items: center;
                .divider {
                    width: 0.5px;
                    height: 20px;
                    background-color: $icon;
                    margin: 0 6px;
                }
                i {
                    color: $icon;
                    cursor: pointer;
                }
            }
        }
        .filter {
            display: flex;
            align-items: center;
            margin: 0 -8px;
            > div,
            button {
                margin: 0 8px;
            }
            .MuiSelect-select {
                min-width: 100px;
            }
        }
    }
    .content {
        margin-top: 32px;

        table {
            border: none;
            border-collapse: collapse;
            margin: 0;
            padding: 0;
            width: 100%;
            table-layout: fixed;
            box-shadow: 0px 2px 6px rgba($color: #000000, $alpha: 0.03);
            background-color: #fff;
        }

        table tr {
            background-color: #fff;
            border: 1px solid #f1f1f3;
            padding: 0.35em;
        }

        table th,
        table td {
            padding: 0.625em;
            text-align: center;
        }

        table th {
            padding: 14px 0;
            font-size: 0.85em;
            text-transform: uppercase;
            font-weight: bold;
            background-color: #f5f6fa;
            color: #a3a6b4;
        }

        table td {
            font-weight: normal;
            i {
                font-size: 18px;
                margin-top: 5px;
                padding: 0 8px;
                cursor: pointer;
                &:first-child {
                    color: $error;
                }
                &:last-child {
                    color: $secondry;
                }
            }
        }
        table div {
            width: 100%;
        }
        .pagination {
            background-color: #fff;
            padding: 16px 0;
        }
    }
    .map {
        margin-top: 32px;
        > div {
            height: 500px;
        }
    }

}

.add-location-map {
    .leaflet-container {
        display: flex !important;
        justify-content: center;
        align-items: center;
        .map-marker {
            position: absolute;
            z-index: 1000;
            color: $primary;
            font-size: 40px;
            margin-bottom: 20px;
            transition: all ease 0.3s;
            &.marker-dragging {
                margin-bottom: 30px;
                font-size: 35px !important;
            }
        }
    }
}
.loc_types{
    .MuiFormControl-root{
        width: 100% !important;
    }
}
.MuiDialog-paperWidthSm{
    min-width: 650px !important;
}