@import '../../../utilities/references/index';
.ruleCard {
    margin: 16px;
    flex: 1;
    padding: 16px;
    background-color: $gray;
    position: relative;
    border-radius: 5px;
    position: relative;
    display: block;
    h3 {
        color: $primary;
        font-size: 16px;
        margin-bottom: 16;
        font-weight: bold;
    }
    p {
        font-size: 14px;
        color: #494a67;
        line-height: 24px;
    }
    > i {
        color: $yellow;
        font-size: 45px;
        margin-bottom: 24px;
    }
    &:hover {
        .actions {
            display: flex;
        }
    }
    .actions {
        position: absolute;
        top: -10px;
        left: 0;
        display: none;
        i {
            font-size: 20px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            cursor: pointer;
            &:first-child {
                color: $error;
                background-color: lighten($color: $error, $amount: 40);
                margin-left: 10px;
            }
            &:last-child {
                color: $secondry;
                background-color: lighten($color: $secondry, $amount: 30);
            }
        }
    }
}
