@import '~bootstrap/scss/bootstrap-grid';
@import '~react-toastify/dist/ReactToastify.css';
@import './font';
@import './card';
@import './icons';

* {
    direction: rtl;
    text-align: right;
    box-sizing: border-box;
}


body {
    padding: 0;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

a {
    outline: none;
    text-decoration: none;
}
