@import '../../utilities/references/index';
@import '~leaflet/dist/leaflet.css';
.all-admin-page {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .title {
            display: flex;
            align-items: center;
            h3 {
                color: $text;
                font-size: 22px;
                margin-left: 10px;
            }
            .icons {
                display: flex;
                align-items: center;
                .divider {
                    width: 0.5px;
                    height: 20px;
                    background-color: $icon;
                    margin: 0 6px;
                }
                i {
                    color: $icon;
                    cursor: pointer;
                }
            }
        }
        .filter {
            display: flex;
            align-items: center;
            margin: 0 -8px;
            position: relative;
            > div,
            button {
                margin: 0 8px;
            }
            .MuiSelect-select {
                min-width: 100px;
            }
        }
    }
    .content {
        margin-top: 32px;

        table {
            border: none;
            border-collapse: collapse;
            margin: 0;
            padding: 0;
            width: 100%;
            table-layout: fixed;
            box-shadow: 0px 2px 6px rgba($color: #000000, $alpha: 0.03);
            background-color: #fff;
        }

        table tr {
            background-color: #fff;
            border: 1px solid #f1f1f3;
            padding: 0.35em;
        }

        table th,
        table td {
            padding: 0.625em;
            text-align: center;
        }

        table th {
            padding: 14px 0;
            font-size: 0.85em;
            text-transform: uppercase;
            font-weight: bold;
            background-color: #f5f6fa;
            color: #a3a6b4;
        }

        table td {
            font-weight: normal;
            i {
                font-size: 18px;
                margin-top: 5px;
                padding: 0 8px;
                cursor: pointer;
            }
        }
        .icons_danger {
            i {
                color: $error !important;
            }
        }
        table div {
            width: 100%;
        }
        .pagination {
            background-color: #fff;
            padding: 16px 0;
        }
    }
    .map {
        margin-top: 32px;
        > div {
            height: 500px;
        }
    }
    .MuiInputBase-root.Mui-disabled {
        color: rgba(0, 0, 0, 0.87) !important;
    }
    .MuiInput-underline.Mui-disabled:before {
        border-bottom-style: solid !important;
    }
}
.filter-days {
    position: absolute;
    z-index: 100;
    top: 47px;
    right: -21px;
    .jalaali.calendarContainer .dayWrapper button {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .selectToday,
    .calendarContainer .daysOfWeek > div {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
}
.filter-select-tag {
    legend {
        width: 55px !important;
    }
}
