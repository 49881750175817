@import '../../../utilities/references/index';
.category_with_no_bg {
    background-color: #f5f6fa !important;

    h3 {
        color: $primary !important;
    }
    p {
        color: #494a67 !important;
    }
}
.category {
    margin: 16px;
    min-width: 250px;
    flex: 1;
    padding: 16px;
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
    border-radius: 5px;
    h3 {
        color: #fff;
        font-size: 16px;
        margin-bottom: 24px;
        font-weight: bold;
    }
    p {
        font-size: 14px;
        color: $gray;
        line-height: 24px;
    }
    > a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        i {
            color: $yellow;
            font-size: 45px;
            margin-bottom: 24px;
        }
    }
    &:hover {
        .actions {
            display: flex;
        }
    }
    .bg_for_category {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.4;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        object-fit: cover;
        top: 0;
        right: 0;
        z-index: -1;
    }
    .actions {
        position: absolute;
        top: -10px;
        left: 0;
        display: none;
        i {
            font-size: 20px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            cursor: pointer;
            &:first-child {
                color: $error;
                background-color: lighten($color: $error, $amount: 40);
                margin-left: 10px;
            }
            &:nth-child(2) {
                color: $secondry;
                background-color: lighten($color: $secondry, $amount: 30);
            }
            &:nth-child(3) {
                margin-right: 10px;
                color: $secondry;
                background-color: lighten($color: $secondry, $amount: 30);
            }
        }
    }
}
