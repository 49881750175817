@import '../../utilities/references/index';
.base {
    .toolbar {
        background-color: $primary;
        display: flex;
        align-items: center;
        padding: 0 16px;
        h3 {
            color: #fff;
        }
    }
    .MuiList-root {
        padding: 0;
        a {
            color: #fff;
            div {
                padding: 8px;
            }
        }
        .is_active {
            > div {
                background-color: $primary !important;
                border-right: 5px solid $accentLightBlue;
            }
        }
        .MuiListItemText-root {
            span {
                font-size: 13px;
                color: #fff;
            }
        }
        .material-icons-outlined {
            color: $icon;
        }
    }
    .MuiListItemIcon-root {
        min-width: unset;
        padding-left: 10px;
        i {
            color: $icon;
        }
    }
}
.MuiToolbar-root {
    background-color: #fff;
    display: flex;
    justify-content: end;
    @media (max-width: 600px) {
        justify-content: space-between;
    }
    .app-bar-actions {
        .user {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            img {
                width: 38px;
                height: 38px;
                border-radius: 50%;
                margin-right: 5px;
            }
            .user-menu {
                span {
                    color: $primary;
                    font-size: 14px !important;
                }
            }
            i {
                font-size: 18px;
                color: $icon;
            }
            .divider {
                height: 20px;
                width: 0.2px;
                opacity: 0.5;
                background-color: $icon;
                margin: 0 5px;
                margin-right: 10px;
            }
        }
    }
}
.MuiDrawer-paperAnchorRight {
    background-color: $bg0e !important;
}
.MuiMenu-paper {
    ul {
        background-color: #fff;
    }
}
.bars {
    color: $primary !important;
}
.MuiAppBar-root {
    box-shadow: 0px 2px 6px rgba($color: #000000, $alpha: 0.05) !important;
}
